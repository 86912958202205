import React from "react";
import './svg.css';

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="969.17"
      height="96.83"
      data-name="Layer 20"
      viewBox="0 0 969.17 96.83"
      className="svg-logo"
    >
      <g fill="#edf0a5">
        <path
          d="M31.75 555.54h-6.91v-13.83h13.83v6.91h6.92v6.92h13.83v-6.92h6.92v-62.25H52.5v-13.83H94v13.83H80.17v69.17h-6.92v6.92h-6.91v6.91H38.67v-6.91h-6.92zM138.67 527.87v41.5h-13.83v-96.83h55.33v6.92h6.92v6.91H194V514h-6.92v7h-6.92v6.91zm0-41.5V514h34.58v-6.92h6.92v-13.79h-6.92v-6.92zM287.09 486.37H294v13.84h-13.83v-6.92h-6.92v-6.92h-27.66v6.92h-6.92v55.33h6.92v6.92h27.66v-6.92h6.92v-20.75h-20.75V514H294v41.5h-6.92v6.92h-6.92v6.91h-41.5v-6.91h-6.92v-6.92h-6.91v-69.13h6.91v-6.91h6.92v-6.92h41.5v6.92h6.92zM359.42 555.54v13.83h-13.83v-13.83zM487.09 486.37H494v13.84h-13.83v-6.92h-6.92v-6.92h-27.66v6.92h-6.92v13.83h6.92V514h34.58v7h6.92v6.91H494v27.67h-6.92v6.92h-6.92v6.91h-41.5v-6.91h-6.92v-6.92h-6.91v-13.87h13.83v6.91h6.92v6.92h27.66v-6.92h6.92v-13.83h-6.92v-6.92h-34.57V521h-6.92v-7h-6.91v-27.63h6.91v-6.91h6.92v-6.92h41.5v6.92h6.92zM552.5 486.37h-27.66v-13.83H594v13.83h-27.66v83H552.5zM631.75 555.54h-6.91v-83h13.83v76.08h6.92v6.92h27.66v-6.92h6.92v-76.08H694v83h-6.91v6.92h-6.92v6.91h-41.5v-6.91h-6.92zM724.84 569.37v-96.83h55.33v6.92h6.92v6.91H794v69.17h-6.91v6.92h-6.92v6.91zm13.83-83v69.17h34.58v-6.92h6.92v-55.33h-6.92v-6.92zM838.67 569.37v-13.83h13.83v-69.17h-13.83v-13.83h41.5v13.83h-13.83v69.17h13.83v13.83zM938.67 479.46v-6.92h41.5v6.92h6.92v6.91H994v69.17h-6.91v6.92h-6.92v6.91h-41.5v-6.91h-6.92v-6.92h-6.91v-69.17h6.91v-6.91zm34.58 6.91h-27.66v6.92h-6.92v55.33h6.92v6.92h27.66v-6.92h6.92v-55.33h-6.92z"
          transform="translate(-24.84 -472.54)"
        ></path>
      </g>
    </svg>
  );
}

export default Logo;
