import React from "react";
import { useSpring, useTrail, animated as a } from "react-spring";
import "./svg.css";

const trailsArr = [
  {
    d: "M58.27,507.22c19.41,0,32,10.33,35.55,26.34H83.2c-2.55-11-11.9-17.85-24.93-17.85-15.15,0-27.47,10.34-27.47,31.3s12.32,31.45,27.47,31.45c13,0,22.24-6.66,24.93-18H93.82c-3.54,15.44-16.14,26.34-35.55,26.34-21.81,0-37.53-15.29-37.53-39.8S36.46,507.22,58.27,507.22Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M145.94,586.81c-21.81,0-38.38-15.29-38.38-39.8s17-39.79,38.66-39.79,38.67,15.15,38.67,39.79S167.75,586.81,145.94,586.81Zm0-8.64c14.59,0,28.75-9.91,28.75-31.16s-13.88-31.15-28.61-31.15-28.47,9.91-28.47,31.15S131.21,578.17,145.94,578.17Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M316,541.49c0-17.14-8.92-25.92-22.66-25.92-14,0-23.93,9.35-23.93,28.19v41.92h-9.78V541.49c0-17.14-9.06-25.92-22.8-25.92-14.16,0-23.94,9.35-23.94,28.19v41.92H203V508.35h9.91v13.31c5-9.77,14.73-14.73,25.64-14.73,12.89,0,23.94,6.09,28.61,19,4.25-12.6,15.58-19,27.9-19,17.14,0,30.74,10.77,30.74,33.43v45.32H316Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M345.48,486.4a7.23,7.23,0,1,1,14.45,0,7.23,7.23,0,1,1-14.45,0Zm2.27,22h9.91v77.33h-9.91Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M438.11,541.49c0-17.14-9.35-25.92-23.51-25.92-14.45,0-24.51,9.07-24.51,27.9v42.21h-9.91V508.35h9.91v13.17c5-9.63,14.88-14.59,26.35-14.59,17.56,0,31.44,10.77,31.44,33.43v45.32h-9.77Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M502.26,507.22c15.3,0,26.2,8.21,30.59,18V508.35h9.92v79.31c0,21.67-14.87,36-36.12,36-20,0-33.85-10.2-37-26.35h9.77c3.26,10.48,13,17.71,27.19,17.71,14.73,0,26.2-9.78,26.2-27.34V568.54c-4.53,9.92-15.29,18.27-30.59,18.27-21.24,0-37-15.72-37-39.94S481,507.22,502.26,507.22Zm1.84,8.64c-16.28,0-28.75,11.33-28.75,31s12.47,31.3,28.75,31.3c15.87,0,28.75-11.89,28.75-31.16S520,515.86,504.1,515.86Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M630.85,586.81c-17.56,0-29.88-8.78-31.15-22.94h10.19c.85,8.5,8.22,14.59,20.82,14.59,11,0,17.28-6,17.28-13.32,0-19.12-46.45-7.93-46.45-37,0-11.47,10.9-21,27.19-21,16.57,0,27.48,8.78,28.33,23.65h-9.92c-.56-8.64-6.94-15.16-18.69-15.16-10.34,0-16.86,5.53-16.86,12.33,0,21,45.75,9.77,46.17,37.1C657.76,577.61,646.86,586.81,630.85,586.81Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M710.3,586.81c-21.81,0-38.38-15.29-38.38-39.8s17-39.79,38.67-39.79,38.66,15.15,38.66,39.79S732.12,586.81,710.3,586.81Zm0-8.64c14.59,0,28.76-9.91,28.76-31.16s-13.88-31.15-28.61-31.15S682,525.77,682,547,695.58,578.17,710.3,578.17Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M801.23,586.81c-21.81,0-38.38-15.29-38.38-39.8s17-39.79,38.66-39.79,38.67,15.15,38.67,39.79S823,586.81,801.23,586.81Zm0-8.64c14.59,0,28.75-9.91,28.75-31.16s-13.88-31.15-28.61-31.15S772.9,525.77,772.9,547,786.5,578.17,801.23,578.17Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M916.23,541.49c0-17.14-9.35-25.92-23.51-25.92-14.45,0-24.5,9.07-24.5,27.9v42.21H858.3V508.35h9.92v13.17c4.95-9.63,14.87-14.59,26.34-14.59,17.56,0,31.44,10.77,31.44,33.43v45.32h-9.77Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M949.65,586.39a7.19,7.19,0,0,1-7.22-7.37,7.15,7.15,0,1,1,14.3,0A7.17,7.17,0,0,1,949.65,586.39Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M975.42,586.39A7.2,7.2,0,0,1,968.2,579a7.15,7.15,0,1,1,14.3,0A7.17,7.17,0,0,1,975.42,586.39Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
  {
    d: "M1001.2,586.39A7.2,7.2,0,0,1,994,579a7.16,7.16,0,1,1,14.31,0A7.17,7.17,0,0,1,1001.2,586.39Z",
    transform: "translate(-20.74 -479.17)",
    fill: "#333f38",
  },
];

function LogoSub(props) {
  const trail = useTrail(trailsArr.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    opacity: props.isInView ? 1 : 0,
    height: props.isInView ? 110 : 0,
    from: { opacity: 0, height: 0 },
    delay: 500
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="987.54"
      height="144.46"
      data-name="Layer 21"
      viewBox="0 0 987.54 144.46"
      className="svg-logo-sub"
    >
      <g>
        {trail.map((style, index) => (
          <a.path
            d={trailsArr[index].d}
            transform={trailsArr[index].transform}
            fill="#333f38"
            key={index}
            style={style}
          />
        ))}
      </g>
    </svg>
  );
}

export default LogoSub;
